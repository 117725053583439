export default {
  pending: [
    {
      sortable: false,
      text: "Added Date",
      value: "created",
      width: 120,
    },
    {
      sortable: false,
      text: "Case id",
      value: "case_id",
      width: 120,
    },
    {
      sortable: false,
      text: "Client",
      value: "client",
      width: 120,
    },
    {
      sortable: false,
      text: "Operations Team",
      value: "assigned_operations",
      width: 130,
    },
    {
      sortable: false,
      text: "Payout For",
      value: "payout_for",
      width: 160,
    },
    {
      sortable: false,
      text: "Amount",
      value: "amount",
      width: 140,
    },
    {
      sortable: false,
      text: "Remarks",
      value: "remark",
      width: 150,
    },
    {
      sortable: false,
      text: "Status",
      value: "status",
    },
    {
      sortable: false,
      text: "Action",
      value: "action",
      width: 300,
    },
  ],

  successful: [
    {
      sortable: false,
      text: "Added Date",
      value: "created",
      width: 120,
    },
    {
      sortable: false,
      text: "Case id",
      value: "case_id",
      width: 120,
    },
    {
      sortable: false,
      text: "Client",
      value: "client",
      width: 120,
    },
    {
      sortable: false,
      text: "Operations Team",
      value: "assigned_operations",
      width: 130,
    },
    {
      sortable: false,
      text: "Payout For",
      value: "payout_for",
      width: 160,
    },
    {
      sortable: false,
      text: "Amount",
      value: "amount",
      width: 140,
    },
    {
      sortable: false,
      text: "Remarks",
      value: "remark",
      width: 150,
    },
    {
      sortable: false,
      text: "Status",
      value: "status",
    },
    {
      sortable: false,
      text: "UTR No.",
      value: "utr_number",
      width: 140,
    },
    {
      sortable: false,
      text: "Payout Date",
      value: "payout_date",
      width: 140,
    },
    {
      sortable: false,
      text: "Action",
      value: "action",
    },
  ],

  cancelled: [
    {
      sortable: false,
      text: "Added Date",
      value: "created",
      width: 120,
    },
    {
      sortable: false,
      text: "Case id",
      value: "case_id",
      width: 120,
    },
    {
      sortable: false,
      text: "Client",
      value: "client",
      width: 120,
    },
    {
      sortable: false,
      text: "Operations Team",
      value: "assigned_operations",
      width: 130,
    },
    {
      sortable: false,
      text: "Payout For",
      value: "payout_for",
      width: 160,
    },
    {
      sortable: false,
      text: "Amount",
      value: "amount",
      width: 140,
    },
    {
      sortable: false,
      text: "Remarks",
      value: "remark",
      width: 150,
    },
    {
      sortable: false,
      text: "Status",
      value: "status",
    },
    {
      sortable: false,
      text: "Cancelled Date",
      value: "status_date",
      width: 127,
    },
    {
      sortable: false,
      text: "Cancelled Reason",
      value: "reject_reason",
      width: 150,
    },
    {
      sortable: false,
      text: "Action",
      value: "action",
    },
  ],
};
