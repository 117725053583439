export default [
  {
    sortable: false,
    text: "Date",
    value: "date",
    width: 120,
  },
  {
    sortable: false,
    text: "Case",
    value: "case",
    width: 180,
  },
  {
    sortable: false,
    text: "Credit",
    value: "credit",
  },
  {
    sortable: false,
    text: "Debit",
    value: "debit",
  },
  {
    sortable: false,
    text: "Balance",
    value: "balance",
  },
  {
    sortable: false,
    text: "Remark",
    value: "remark",
  },
];
