<template>
  <v-dialog
    scrollable
    persistent
    v-model="show"
    width="480px"
    content-class="rounded-xl"
  >
    <v-card :outlined="$vuetify.theme.dark" rounded="xl">
      <!-- dialog title -->
      <v-card-title class="d-flex align-center justify-space-between">
        <div class="text-body-1">Reject Refund</div>
        <v-btn small icon @click="modalClosed">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider />

      <!-- dialog content -->
      <v-card-text>
        <v-form class="mt-6" lazy-validation ref="form">
          <v-select
            :menu-props="{
              bottom: true,
              offsetY: true,
            }"
            append-icon="mdi-chevron-down"
            v-model="form.reason_id"
            :items="reason_list"
            item-text="title"
            item-value="id"
            flat
            outlined
            dense
            label="Select Reason"
            :rules="[$rules.required]"
          ></v-select>

          <v-textarea
            v-if="form.reason_id === 0"
            v-model="form.reason_other"
            :rules="[$rules.required]"
            label="Type reason here..."
            :rows="3"
            outlined
            dense
          ></v-textarea>
        </v-form>
      </v-card-text>

      <v-divider />

      <!-- dialog actions -->
      <v-card-actions class="py-3">
        <v-spacer />
        <v-btn
          rounded
          outlined
          color="primary"
          class="text-capitalize px-6"
          :loading="btnLoading"
          @click="submit"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      btnLoading: false,
      reason_list: [],
      form: {
        reason_id: null,
        reason_other: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      rejectDialog: "refund/rejectDialog",
    }),

    show: {
      get() {
        return this.rejectDialog.show;
      },
      set(value) {
        this.toggleDialog({ show: value });
      },
    },
  },
  watch: {
    show(value) {
      if (value) {
        this.openModal();
      } else this.modalClosed();
    },
  },
  methods: {
    ...mapActions({
      toggleDialog: "refund/toggleRejectDialog",
    }),

    openModal() {
      this.getReasonList();
    },

    modalClosed() {
      this.toggleDialog({ show: false });
      this.$refs.form.reset();
    },

    getReasonList() {
      const onSuccess = (res) => {
        this.reason_list = res.data.data;
        this.reason_list.push({
          id: 0,
          title: "Other Reason",
          type: "other",
        });
      };

      const params = {
        reason_type: "reject",
        apply_pagination: false,
      };

      return this.$request(this.$keys.GET, this.$urls.master.reason.list, {
        params,
        onSuccess,
      });
    },

    submit() {
      if (this.$refs.form.validate()) {
        this.btnLoading = true;

        const onSuccess = () => {
          this.modalClosed();
          this.$emit("submitted");
        };

        const onFinally = () => {
          this.btnLoading = false;
        };

        const data = {
          refund_id: this.rejectDialog.id,
          ...this.form,
        };

        return this.$request(this.$keys.POST, this.$urls.case.refund.reject, {
          data,
          onSuccess,
          onFinally,
        });
      }
    },
  },
};
</script>
