<template>
  <div>
    <!-- dataTable -->
    <v-data-table
      :loading="loading"
      class="elevation-3 rounded-t-0"
      loading-text="Please wait ....Loading"
      :headers="headers"
      :items="items"
      hide-default-footer
      mobile-breakpoint
    >
      <template v-slot:[`item.date`]="{ item }">
        {{ $utils.getDateFormat(item.status_datetime) }}
      </template>
      
      <template v-slot:[`item.refund_date`]="{ item }">
        {{ $utils.getDateFormat(item.refund_date) }}
      </template>

      <template v-slot:[`item.case_id`]="{ item }">
        {{ item.case.case_id }}
      </template>

      <template v-slot:[`item.client`]="{ item }">
        <div>
          {{ item.case.client.name }}
          <div v-if="item.case.client.email">{{ item.case.client.email }}</div>
          <div v-if="item.case.client.mobile">
            {{ item.case.client.mobile_display }}
          </div>
        </div>
      </template>

      <template v-slot:[`item.clause`]="{ item }">
        <div v-if="item.clause">
          {{ item.clause.name }} - {{ item.clause.refund_percent }}%
        </div>
      </template>

      <template v-slot:[`item.amount`]="{ item }">
        <div>University: ₹{{ item.university_cost }}</div>
        <div>Processing: ₹{{ item.processing_cost }}</div>
        <div>Shipping: ₹{{ item.shipping_cost }}</div>
        <div>Total: ₹{{ item.total_refund_amount }}</div>
      </template>

      <template v-slot:[`item.remarks`]="{ item }">
        <div v-if="item.remarks">
          {{ item.remarks }}
        </div>
        <v-icon v-else>mdi-minus</v-icon>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-chip small class="text-capitalize font-weight-bold">
          {{ item.status_display }}
        </v-chip>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <div class="d-flex justify-center" style="gap: 10px">
          <v-btn
            small
            rounded
            outlined
            color="primary"
            class="text-capitalize"
            v-for="action in item.actions"
            :key="`action-${action.key}`"
            @click="actionHandler(action.key, item)"
          >
            <v-icon left v-if="action.icon">mdi-{{ action.icon }}</v-icon>
            {{ action.text }}
          </v-btn>
        </div>
      </template>
    </v-data-table>

    <!-- pagination -->
    <v-container
      class="d-flex justify-space-between align-center px-0 mb-0 mt-4"
      fluid
    >
      <div class="d-flex align-center text-subtitle-2">
        <div class="mr-2">Rows per page</div>
        <div style="width: 75px">
          <v-select
            :menu-props="{ bottom: true, offsetY: true }"
            :items="itemsPerPageList"
            v-model="page_count"
            hide-details
            dense
            solo
            color="primary"
          ></v-select>
        </div>
        <div class="ml-6">
          Results: {{ pageStart }} - {{ pageEnd }} of {{ total_count }}
        </div>
      </div>
      <v-pagination
        v-model="page_number"
        :length="page_total"
        :total-visible="5"
        color="primary"
      >
      </v-pagination>
    </v-container>

    <ApproveDialog @submitted="reloadData" />
    <RejectDialog @submitted="reloadData" />
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";

import { refundTable } from "@/headers";
import ApproveDialog from "@/components/CaseManagement/Refund/ApproveDialog";
import RejectDialog from "@/components/CaseManagement/Refund/RejectDialog";

export default {
  components: { ApproveDialog, RejectDialog },

  data() {
    return {
      loading: false,
      items: [],

      page_total: 1,
      page_number: 1,
      total_count: 0,
      itemsPerPageList: [5, 10, 15, 20, 30, 50],
      page_count: 10,
    };
  },

  computed: {
    ...mapGetters({
      search: "refund/search",
      filters: "refund/filters",
      activeTab: "refund/activeTab",
    }),

    headers() {
      return refundTable[this.activeTab.key];
    },

    pageStart() {
      let start = (this.page_number - 1) * this.page_count + 1;
      if (start + 1 > this.total_count) start = this.total_count;
      return start;
    },

    pageEnd() {
      let end = this.pageStart + this.page_count - 1;
      if (end > this.total_count) end = this.total_count;
      return end;
    },
  },

  watch: {
    page_count() {
      this.getRefundList();
    },

    page_number() {
      this.getRefundList();
      this.updateRoute();
    },

    activeTab: {
      handler() {
        this.reloadData();
      },
      deep: true,
    },

    search() {
      this.debouncedReloadData();
    },

    filters: {
      handler() {
        this.reloadData();
        this.updateRoute();
      },
      deep: true,
    },
  },

  mounted() {
    this.getRefundList();
  },

  created() {
    const query = this.$route.query;

    if (query.page) {
      this.page_number = Number(query.page);
    }

    if (query.search) {
      this.setSearch(String(query.search));
    }

    if (query.date) {
      this.setFilters({ date: [String(query.date)] });
    }

    if (query.start_date && query.end_date) {
      this.setFilters({
        date: [String(query.start_date), String(query.end_date)],
      });
    }

    this.updateRoute();
  },

  methods: {
    ...mapActions({
      setSearch: "refund/setSearch",
      setFilters: "refund/setFilters",
      showSnackbar: "snackBar/showSnackbar",
      toggleUpdateRefundDialog: "refund/toggleAddUpdateDialog",
      toggleApproveDialog: "refund/toggleApproveDialog",
      toggleRejectDialog: "refund/toggleRejectDialog",
    }),

    debouncedReloadData: _.debounce(function () {
      this.reloadData();
      this.updateRoute();
    }, 1000),

    reloadData() {
      this.$emit("reloadData");
      this.getRefundList();
    },

    updateRoute() {
      const query = {};

      if (this.search) {
        query.search = String(this.search);
      }

      if (this.page_number) {
        query.page = Number(this.page_number);
      }

      if (this.filters.date.length) {
        if (this.filters.date.length === 2) {
          query.start_date = String(this.filters.date[0]);
          query.end_date = String(this.filters.date[1]);
        } else {
          query.date = String(this.filters.date[0]);
        }
      }

      this.$router
        .replace({
          path: this.$route.path,
          query: query,
        })
        .catch((error) => error);
    },

    getRefundList() {
      this.loading = true;
      this.items = [];

      const onSuccess = (res) => {
        this.items = res.data.data;
      };

      const onFinally = () => {
        this.loading = false;
      };

      const params = {
        case_id: this.caseId,
        search: this.search,
        refund_status: this.activeTab.key,
      };

      if (this.filters.date) {
        if (this.filters.date.length === 2) {
          params["start_date"] = this.filters.date[0];
          params["end_date"] = this.filters.date[1];
        } else {
          params["date"] = this.filters.date[0];
        }
      }

      return this.$request(this.$keys.GET, this.$urls.case.refund.list, {
        params,
        onSuccess,
        onFinally,
        cancel: true,
      });
    },

    actionHandler(key, item) {
      switch (key) {
        case "view_case": {
          this.viewCase(item.case.id);
          break;
        }

        case "approve": {
          this.toggleApproveDialog({ show: true, id: item.id });
          break;
        }

        case "reject": {
          this.toggleRejectDialog({ show: true, id: item.id });
          break;
        }

        default:
          break;
      }
    },

    viewCase(caseId) {
      this.$router.push({ name: "caseDetail", params: { id: caseId } });
    },
  },
};
</script>