export default {
  fe_ir_ledger: [
    {
      sortable: false,
      text: "#",
      value: "sn",
      width: 120,
    },
    {
      sortable: false,
      text: "FE/IR Name",
      value: "name",
      width: 180,
    },
    {
      sortable: false,
      text: "Contact",
      value: "contact",
      width: 180,
    },
    {
      sortable: false,
      text: "Role",
      value: "role",
    },
    {
      sortable: false,
      text: "Balance",
      value: "balance",
    },
    {
      sortable: false,
      text: "Action",
      value: "action",
    },
  ],

  university_ledger: [
    {
      sortable: false,
      text: "#",
      value: "sn",
      width: 120,
    },
    {
      sortable: false,
      text: "University Name",
      value: "name",
      width: 180,
    },
    {
      sortable: false,
      text: "Contact",
      value: "contact",
      width: 180,
    },
    {
      sortable: false,
      text: "Balance",
      value: "balance",
    },
    {
      sortable: false,
      text: "Action",
      value: "action",
    },
  ],
};
